export const EXPERIMENTS_SCOPE = 'challenges';
export const ARTIFACT_VERSION = process.env.ARTIFACT_VERSION;
export enum VIEW_MODE {
  Editor = 'Editor',
  Preview = 'Preview',
  Site = 'Site',
}

export const enum CHALLENGES_BM_ROUTES {
  Home = '/challenges',
  CreateProgram = '/challenges/onboarding/regular',
}

export enum Challenges {
  enableVisibilityByName = 'specs.challenges.OOIEnableVisibilityByName',
  enableDripContent = 'specs.challenges.WebMemberDripContent',
  skipMAInstallation = 'specs.programs.OOISkipMAInstallationUsers',
  enableUndoButton = 'specs.programs.OOIEnableUndoButton',
  hideStartDate = 'specs.challenges.OOIHideStartDateSelection',
  enableHideTeamEmail = 'specs.challenges.OOIHideSupportEmail',
  showSettingsPanel = 'specs.programs.ShowPaymentPageSettingsStudio',
  enableParticipantPageInEditor = 'specs.programs.EnableParticipantPage',
  redirectToParticipantPage = 'spec.programs.RedirectToParticipantPage',
  enablePremium = 'specs.programs.OOIEnablePremium',
  enableParticipantPageMigrations = 'specs.programs.EnableParticipantPageMigrations',
  enableExtendedLimits = 'specs.programs.OOIExtendedLimits',
  enableOOIStyleBP = 'specs.programs.OOIStyleBP',
  enableCustomPageInfo = 'specs.programs.EnableEditorCustomPageInfo',
  enableLinksAsBlank = 'specs.programs.OOIEnableLinksAsBlank',
  enableBidirectionalAlignment = 'specs.programs.EnableBidirectionalAlignment',
  handleCustomizedUrlSegments = 'spec.programs.HandleCustomizedUrlSegments',
}
export type ExperimentsKeys = `${Challenges}`;
